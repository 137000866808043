import React from "react";
import { Dialog, DialogContent, DialogTitle, Slide } from "@material-ui/core";

import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button";
import Close from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { nftService } from "services/nftService";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

NftView.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func,
}
export default function NftView({ id, onClose }) {

  const [modal, setModal] = React.useState(false);
  const [nft, setNft] = React.useState({});

  async function fetchNFTs() {
    try {
      const { data } = await nftService.view(id);
      setNft(data.nft);
    } catch (e) {
      console.log(e);
    }
  }

  function close() {
    onClose();
  }

  React.useEffect(() => {
    if (id) {
      fetchNFTs();
      setModal(true);
    } else {
      setModal(false);
    }
  }, [id])

  const classes = useStyles();

  const renderType = (type) => {
    switch (type) {
      case "video":
        return "Video";
      case "audio":
        return "Audio";
      default:
        return "Image";
    }
  }

  const renderImage = (type, value) => {
    switch (type) {
      case "image":
        return <img src={value} className="lazy nft__item_preview" width="100%" />;
      case "video":
        return <video width="100%" controls src={value}>
        </video>
      case "audio":
        return <audio style={{ width: '100%' }} controls muted width="100%">
          <source src={value} />
        </audio>;
      default:
        return <></>;
    }
  }

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      open={modal}
      transition={Transition}
      keepMounted
      onClose={close}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={close}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>Category: {nft.title}</h4>
      </DialogTitle>
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <h5><strong>Image: </strong></h5>
            {renderImage(nft?.fileType, nft?.image)}
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>

            <h5><strong>Name:</strong></h5>
            <h5>{nft?.name}</h5>

            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <h5><strong>Sell method:</strong></h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h5>{nft.isAuction ? 'Auction' : 'Set price'}</h5>
              </GridItem>
            </GridContainer>


            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <h5><strong>Creator: </strong></h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h5>{nft?.creator?.username}</h5>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <h5><strong>Type: </strong></h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h5>{renderType(nft?.fileType)}</h5>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <h5><strong>Create At: </strong></h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h5>{nft.createdAt}</h5>
              </GridItem>
            </GridContainer>
            {/*<GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <h5><strong>Creator: </strong></h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h5>{nft.creator}</h5>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <h5><strong>Minter: </strong></h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h5>{nft.minter}</h5>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <h5><strong>Owner: </strong></h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h5>{nft.owner}</h5>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <h5><strong>Seller: </strong></h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h5>{nft.seller}</h5>
              </GridItem>
            </GridContainer>
*/}
            <h5><strong>Description:</strong></h5>
            <h5>{nft.description}</h5>

          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  )
}
