import React from "react";
import PropTypes from "prop-types";
import TablePaginate from "components/TablePaginate/TablePaginate";
import Select from "components/Select/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import { nftService } from "services/nftService";
import { makeStyles, Switch } from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import { Visibility } from "@material-ui/icons";
import NftView from "./NftView";
import * as moment from "moment"

const useStyles = makeStyles({
    filter: {
        display: "flex",
        gap: "40px",
    },
});

export default function NftIndex() {
    const classes = useStyles();
    const [items, setItems] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const [fileType, setFileType] = React.useState("");
    const [viewId, setViewId] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [size] = React.useState(10);
    const [count, setCount] = React.useState(0);


    const columns = React.useMemo(
        () => [
            {
                Header: "Thumbnail",
                accessor: "image",
                Cell: Thumnail,
            },
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Creator",
                accessor: "creator.username",
            },
            {
                Header: "Type",
                accessor: "fileType",
                Cell: Type,
            },
            {
                Header: "Create At",
                accessor: "createAt",
                Cell: CreateAt,
            },
            {
                Header: "Action",
                Cell: function remove({ cell }) {
                    return (
                        <Action
                            cell={cell}
                            handleView={(row) => setViewId(row.id)}

                        />
                    );
                },
            },
        ],
        []
    );

    function CreateAt({ cell }){
        return moment(cell.value).format("MM/DD/YYYY")
    }

    CreateAt.propTypes = {
        cell : PropTypes.any
    }

    async function fetchNfts() {
        try {
            const { data } = await nftService.list({
                search,
                page,
                size,
                fileType,
            });
            setItems(data.items);
            setCount(data.paginate.count);
            setPage(data.paginate.page);
        } catch (e) {
            console.log(e);
        }
    }


    React.useEffect(() => {
        fetchNfts();
    }, [page, search, fileType]);

    return (
        <div>
            <NftView id={viewId} onClose={() => setViewId('')} />

            {confirm}

            <div className={classes.filter}>
                <CustomInput
                    labelText="Search"
                    id="search"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        value: search,
                        onChange: (e) => setSearch(e.target.value),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />

                <Select
                    label={"TYPE"}
                    value={fileType}
                    onChange={setFileType}
                    options={[
                        { value: "", label: "ALL" },
                        { value: "image", label: "IMAGE" },
                        { value: "video", label: "VIDEO" },
                        { value: "audio", label: "AUDIO" },
                    ]}
                />
            </div>

            <TablePaginate
                count={count}
                page={page}
                setPage={setPage}
                size={size}
                columns={columns}
                data={items}
            />
        </div>
    );
}

function Active({ cell }) {
    return (
        <Switch
            checked={cell.value}
            inputProps={{ "aria-label": "controlled" }}
            color="primary"
        />
    );
}

Active.propTypes = {
    cell: PropTypes.any,
};

function Action({ cell, handleView }) {
    return (
        <div className="actions-right">
            <Button justIcon round simple color="success" onClick={() => handleView(cell.row.original)}>
                <Visibility />
            </Button>
        </div>
    );
}

Action.propTypes = {
    cell: PropTypes.any,
    handleDelete: PropTypes.func,
    handleView: PropTypes.func,
    handleMint: PropTypes.func,
};

function Thumnail({ cell }) {
    const type = cell.row.original.fileType;
    const value = cell.value;
    switch (type) {
        case "video":
            return <video width="100%" controls src={value}>
            </video>
        case "audio":
            return <audio style={{ width: '100%' }} controls muted>
                <source src={value} />
            </audio>;
        default:
            return <img src={value} className="lazy nft__item_preview" height="50px" />;
    }
}

Thumnail.propTypes = {
    cell: PropTypes.any,
};

function Type({ cell }) {
    const type = cell.value;
    switch (type) {
        case "video":
            return "Video";
        case "audio":
            return "Audio";
        default:
            return "Image";
    }
}
Type.propTypes = {
    cell: PropTypes.any,
};
