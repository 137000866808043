import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TablePaginate from "components/TablePaginate/TablePaginate";
import Select from "components/Select/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import { userService } from "services/userService";
import { makeStyles, Switch } from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import { Visibility } from "@material-ui/icons";
import { toast } from "react-toastify";
import * as moment from "moment"

const useStyles = makeStyles({
    filter: {
        display: "flex",
        gap: "40px",
    },
});

export default function UserIndex() {
    const classes = useStyles();
    const [items, setItems] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [size] = React.useState(10);
    const [count, setCount] = React.useState(0);

    const columns = React.useMemo(
        () => [
            {
                Header: "Username",
                accessor: "username",
            },
            {
                Header: "Full Name",
                accessor: "title",
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Wallet Address",
                accessor: "address",
            },
            {
                Header: "Create At",
                accessor: "createAt",
                Cell: CreateAt,
            },
            {
                Header: "Action",
                Cell: function remove({ cell }) {
                    return (
                        <Action
                            cell={cell}
                        />
                    );
                },
            },
        ],
        []
    );

    function CreateAt({ cell }) {
        return moment(cell.value).format("MM/DD/YYYY")
    }

    CreateAt.propTypes = {
        cell: PropTypes.any
    }

    async function fetchUsers() {
        try {
            const { data } = await userService.list({
                search,
                page,
                size,
                status,
            });
            setItems(data.items);
            setCount(data.paginate.count);
            setPage(data.paginate.page);
        } catch (e) {
            console.log(e);
        }
    }

    React.useEffect(() => {
        fetchUsers();
    }, [page, search, status]);

    return (
        <div>
            <div className={classes.filter}>
                <CustomInput
                    labelText="Search"
                    id="search"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        value: search,
                        onChange: (e) => setSearch(e.target.value),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />

                <Select
                    label={"Status"}
                    value={status}
                    onChange={setStatus}
                    options={[
                        { value: "", label: "ALL" },
                        { value: "active", label: "ACTIVATED" },
                        { value: "unactive", label: "BLOCKED" },
                    ]}
                />
            </div>

            <TablePaginate
                count={count}
                page={page}
                setPage={setPage}
                size={size}
                columns={columns}
                data={items}
            />
        </div>
    );
}

function IsCreator({ cell }) {
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        setChecked(cell.value);
    }, [cell]);

    function onChange(e) {
        setChecked(e.target.checked);
        toogleChecked(e.target.checked);
    }

    async function toogleChecked(isChecked) {
        const id = cell.row.original.id;
        try {
            const { data } = await userService.update(id, { isCreator: isChecked });
            console.log(data);
            toast.success("Updated user");
        } catch (e) {
            toast.error(e.response.data.message[0]);
        }
    }
    return (
        <Switch
            checked={checked}
            onChange={onChange}
            inputProps={{ "aria-label": "controlled" }}
            color="primary"
        />
    );
}

IsCreator.propTypes = {
    cell: PropTypes.any,
};

function Action({ cell }) {
    return (
        <div className="actions-right">
            <Link to={"/admin/user/" + cell.row.original.id}>
                <Button
                    justIcon
                    round
                    simple
                    color="success"
                    className="edit"
                >
                    <Visibility />
                </Button>
            </Link>
        </div>
    );
}

Action.propTypes = {
    cell: PropTypes.any,
};
