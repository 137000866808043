import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import "react-quill/dist/quill.snow.css";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { useParams, useHistory } from "react-router-dom";
import Button from "../../components/CustomButtons/Button";
import { Dialog, DialogContent, DialogTitle, Slide } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { collectionService } from "services/collectionService";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CollectionView() {
  const [modal, setModal] = React.useState(false);
  const [nft, setNft] = React.useState({});
  const { id } = useParams();
  const history = useHistory();

  async function fetchCollection(id) {
    const { data } = await collectionService.view(id);
    setNft(data);
    setModal(true);
  }

  React.useEffect(() => {
    fetchCollection(id);
  }, []);

  const classes = useStyles();

  function close() {
    setModal(false);
    history.push("/admin/collection");
  }

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={modal}
      transition={Transition}
      keepMounted
      onClose={close}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={close}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>{nft.title}</h4>
      </DialogTitle>
      <DialogContent id="modal-slide-description" className={classes.modalBody}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <h5>
              <strong>Image: </strong>
            </h5>
            <img src={nft?.image} alt="..." width="100%" />
            {nft.cover && (
              <div>
                <h5>
                  <strong>Cover: </strong>
                </h5>
                <img src={nft?.cover} alt="..." width="100%" />
              </div>
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <h5>
                  <strong>Name:</strong>
                </h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
<h5>{nft.name}</h5>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <h5>
                  <strong>Symbol: </strong>
                </h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h5>{nft.symbol}</h5>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <h5>
                  <strong>Address: </strong>
                </h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h5>{nft.address}</h5>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <h5>
                  <strong>Owner: </strong>
                </h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h5>{nft.owner}</h5>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
}